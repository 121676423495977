import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import NotificationArea from 'component/NotificationArea';
import Login from 'component/Login';
import AppHeader from './AppHeader';
import { withRouter } from 'react-router-dom';
import Router from './Router';
import StartupError from './StartupError';
import RuntimeError from './RuntimeError';
import { AppContainer, Body } from 're-cy-cle';
import * as Sentry from '@sentry/react';
import { theme } from 'styles';
import { Helmet } from 'react-helmet';
import { TAB_TITLE_PREFIX } from 'helpers';
import { ModuleRepository } from '_iae/module/repository';

const pathsThatSkipAuthentication = [
  /^\/login\/forgot$/,
  /^\/user\/\d+\/reset-password\/[^/]+$/,
  /^\/register/,
];

@withRouter
@observer
export default class App extends Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
    moduleRepository: PropTypes.instanceOf(ModuleRepository).isRequired,
    location: PropTypes.object,
  };

  static childContextTypes = {
    viewStore: PropTypes.object,
  };

  getChildContext() {
    return {
      viewStore: this.props.store,
    };
  }

  componentDidCatch(err) {
    this.hasCrashed = true;
    if (process.env.CY_FRONTEND_SENTRY_DSN) {
      Sentry.captureException(err);
      Sentry.showReportDialog();
    }
  }

  @observable hasCrashed = false;

  render() {
    const { store, location, moduleRepository } = this.props;

    if (this.hasCrashed) {
      return <RuntimeError />;
    }

    let content = null;
    if (
      store.isAuthenticated ||
      pathsThatSkipAuthentication.some(regex =>
        regex.test(location.pathname)
      )
    ) {
      content = <Router store={store} moduleRepository={moduleRepository} />;
    } else if (store.bootstrapCode === 200) {
      content = <Login viewStore={store} />;
    } else if (store.bootstrapCode !== null) {
      // The not null check is important, since we don't want to flash a startup error while the XHR request is running.
      return <StartupError code={store.bootstrapCode} />;
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>{TAB_TITLE_PREFIX}</title>
        </Helmet>
        <AppContainer data-theme={JSON.stringify(theme)}>
          <AppHeader store={store} location={location} moduleRepository={moduleRepository} />
          <Body>{content}</Body>
          {store.currentModal ? (
            <store.currentModal.render
              viewStore={store}
              {...store.currentModal}
            />
          ) : null}
          <NotificationArea store={store} />
        </AppContainer>
      </React.Fragment>
    );
  }
}
