import _ from 'lodash';

export default function UserPermissionMixin(superclass) {
    return class extends superclass {
        // Returns the correct set of permissions depending on the framework we are using
        getPermissions() {
            if (!this.permissions) {
                return this.get('permissions');
            } else {
                return this.permissions.map(p => [p[0], p[1]]);
            }
        }
        /**
         * Permission check that iterates over all arguments and returns true
         * if any of the arguments contains a permission / permission:scope
         * that is allowed.
         *
         * For now the scope is ignored, because the backend will only send
         * data that belongs inside the current scope.
         * Scope functionality should be added in a later stage.
         * TODO: include scope in permission check
         *
         * @return {Boolean}
         */
        may() {
            let may = true;

            _.each(arguments, permissionScope => {
                const perm = permissionScope.split(':');
                const permission = perm[0];

                let mayThis = false;

                _.find(this.getPermissions(), permArray => {
                    // ignore scope for now, maybe implement later...
                    if (permission === permArray[0]) {
                        // && (scope === null || scope === permArray[1])) {
                        mayThis = true;
                        return true;
                    }
                    return false;
                });

                if (!mayThis) {
                    may = false;
                }
            });
            return may;
        }

        /**
         *
         *
         * @return {Boolean}
         */
        scope() {
            let may = true;

            _.each(arguments, permissionScope => {
                const perm = permissionScope.split(':');
                const permission = perm[0];

                let mayThis = false;
                _.find(this.get('permissions'), permArray => {
                    // ignore scope for now, maybe implement later...
                    if (
                        permission === permArray[0] &&
                        (permArray[1] === 'all' || permArray[1] === 'branch')
                    ) {
                        // && (scope === null || scope === permArray[1])) {
                        mayThis = true;
                        return true;
                    }
                    return false;
                });

                if (!mayThis) {
                    may = false;
                }
            });
            return may;
        }

        /**
         * The low level permissions on the server looks as follows:
         *
         * {
         *     permissions: [
         *         ["auth.view_user", "branch"],
         *         ["call.view_call", "all"],
         *         ["subscription.delete_metafieldvalue", "all"],
         *         ["company.delete_shopitem", "all"]
         *     ]
         * }
         *
         * Now you can use this function as follows:
         * ```
         * hasLowLevelPermission('auth.view_user') -> true
         * hasLowLevelPermission('auth.view_user', 'branch') -> true
         * hasLowLevelPermission('auth.view_user', 'all') -> false
         * hasLowLevelPermission('auth.view_user', ['branch', 'all']) -> true
         */
        hasLowLevelPermission(base, scopes) {
            const ownedScopes = [];

            this.permissions.forEach(p => {
                const Pbase = p.split(':')[0]
                if (Pbase === base) {
                    ownedScopes.push(p);
                }
            });

            if (scopes) {
                const scopesArray = Array.isArray(scopes) ? scopes : [scopes];
                return _.intersection(ownedScopes, scopesArray).length > 0;
            }

            return ownedScopes.length > 0;
        }

        /**
         * The low level permissions on the server looks as follows:
         *
         * {
         *     permissions: [
         *         ["auth.view_user", "branch"],
         *         ["call.view_call", "all"],
         *         ["subscription.delete_metafieldvalue", "all"],
         *         ["company.delete_shopitem", "all"]
         *     ]
         * }
         *
         * Now you can use this function as follows:
         * ```
         * hasSomeLowLevelPermission({
         *     'auth.view_user': ['branch', 'all'],
         * }) -> true
         */
        hasSomeLowLevelPermission(permissions) {
            return _.some(Object.keys(permissions), base =>
                this.hasLowLevelPermission(base, permissions[base])
            );
        }
    };
}
