import { tint } from 'polished';

const primaryColor = '#426B42';
const tintedPrimaryColor = tint(0.15, primaryColor);
const secondaryColor = '#DCE5B3';
const tintedSecondaryColor = tint(0.15, secondaryColor);
const ternaryColor = '#F0F0F0';
const tintedTernaryColor = tint(0.15, ternaryColor);
const buttonColor = '#48b4ca';
const dangerColor = '#dc0818';
const warningColor = '#ffc107';

export const theme = {
  primaryColor,
  tintedPrimaryColor,
  secondaryColor,
  tintedSecondaryColor,
  ternaryColor,
  tintedTernaryColor,
  buttonColor,
  dangerColor,
  warningColor,
};
